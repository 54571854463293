import { Route, Routes } from "react-router";
import "./App.css";
import LandingPage from "./LandingPage";
import SiteCheck from "./SiteCheck"; // Check the file path and name

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/:macAddress" element={<SiteCheck />} />
      </Routes>
    </>
  );
}

export default App;
