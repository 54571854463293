import React from 'react';
import './SignalBar.css'; // Ensure this CSS file is created in your project

const SignalBar = ({ strength }) => {
  const getBarStatus = (barIndex) => {
    if ((strength > 0 && strength < 11 && barIndex === 0) ||
        (strength >= 11 && strength < 15 && barIndex < 2) ||
        (strength >= 15 && strength < 20 && barIndex < 3) ||
        (strength >= 20 && barIndex < 4)) {
      return 'signal-bar-active';
    }
    return '';
  };

  return (
    <div className="signal-bar-container" style={{ maxWidth: '35px', height: '25px', gap: '3px', display: 'flex', alignItems: 'flex-end'}}>
      {Array.from({ length: 4 }, (_, index) => (
        <div
          key={index}
          className={`signal-bar ${getBarStatus(index)}`}
          style={{ height: `${25 + index * 25}%`}} // Add border here
        ></div>
      ))}
    </div>
  );
};

export default SignalBar;
