import React, { useState, useRef } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  Dialog,
  DialogContent,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import logo from "./media/ilogo.png";
import logo1 from "./media/logo.png";
import "./LandingPage.css";

const preloadImage = (src) => {
  const image = new Image();
  image.src = src;
};
preloadImage("./media/background.png");
preloadImage("./media/ilogo.png");
preloadImage("./media/logo.png");

function LandingPage() {
  const [macAddress, setMacAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [infoOpen, setInfoOpen] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event) => {
    // only allow hexadecimal characters
    const cleanValue = event.target.value
      .toUpperCase()
      .replace(/[^0-9A-F]/gi, "");

    // add a colon every two characters
    let formattedValue = cleanValue.match(/.{1,2}/g)?.join(":");

    if (formattedValue) {
      // check that length of serial is even, and less than max length
      if (cleanValue.length % 2 === 0 && cleanValue.length < 12) {
        // add a colon if not currently deleting characters
        if (event.nativeEvent.inputType !== "deleteContentBackward") {
          formattedValue += ":";
        }
      }
    } else {
      // reset if no valid characters
      formattedValue = "";
    }

    // update state with the serial
    setMacAddress(formattedValue.substr(0, 17));
    setErrorMessage("");

    // set timeout to ensure cursor and focus management happens after state updates
    setTimeout(() => {
      if (inputRef.current) {
        // refocus on the input element
        inputRef.current.focus();
        // get current cursor position
        let cursorPosition = event.target.selectionStart;
        // if deleting
        if (
          event.nativeEvent.inputType === "deleteContentBackward" &&
          cursorPosition < formattedValue.length
        ) {
          // adjust cursor back one position
          cursorPosition = cursorPosition - 1 >= 0 ? cursorPosition - 1 : 0;
        }
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          cursorPosition;
      }
    }, 0);
  };

  const validateMacAddress = () => {
    if (macAddress.length !== 17) {
      setErrorMessage("Invalid format.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateMacAddress()) return;

    const isValid = await checkMacAddress();
    if (isValid) {
      const url = `${window.location.origin}/${macAddress}`;
      console.log("Redirect to:", url);
      window.location.href = url;
    }
  };

  const handleInfoClick = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  // See if the mac address entered is in our system (valid)
  const checkMacAddress = async () => {
    const url = `https://vpp.diamondgem.com.au:5003/installer_hub/monitoring/${macAddress}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Check if the response was successful
      if (!response.ok) {
        if (response.status === 500) {
          throw new Error(
            "SolariTRACK serial does not exist. Check input and try again."
          );
        } else {
          throw new Error(
            "SolariTRACK serial does not exist. Check input and try again."
          );
        }
      }

      const data = await response.json();
      if (data.error === "Invalid") {
        setErrorMessage("Invalid Serial Number.");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setErrorMessage(error.message);
      return false;
    }
  };

  return (
    <Box className="container">
      <Box sx={{ p: isMobile ? 1 : 3 }}>
        <Grid item xs={12} sm={8} md={6}>
          <Box className="login-form">
            <Box className="logos-container">
              <img src={logo} alt="First Logo" className="login-logo" />
              <img src={logo1} alt="Second Logo" className="login-logo" />
            </Box>

            <Typography
              gutterBottom
              variant={isMobile ? "subtitle1" : "h6"}
              sx={{ fontWeight: "bold" }}
            >
              SolariTRACK Installer Hub
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {errorMessage && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 2, fontSize: "0.8rem" }}
                >
                  {errorMessage}
                </Typography>
              )}
              <TextField
                label="Serial"
                type="text"
                value={macAddress}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "white",
                  marginBottom: "15px",
                  borderRadius: 1,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HelpIcon
                        onClick={handleInfoClick}
                        edge="end"
                        sx={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Dialog
                onClose={handleInfoClose}
                open={infoOpen}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      SolariTRACK serial number can be found on the back of the
                      device (format 01:23:45:67:AB:CD).
                    </Typography>
                    <Button
                      color="success"
                      sx={{ fontWeight: "bold" }}
                      onClick={handleInfoClose}
                    >
                      Close
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                Connect
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default LandingPage;
